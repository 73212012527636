import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Rule} from '../../../../models/rule.model';
import {LabelService} from '../../../../services/label.service';
import {LibraryService} from '../../../../services/library.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {isTFLibrary, SimpleLibrary} from '../../../../models/simple-library.model';
import {DialogService} from '../../../../services/dialog.service';
import {SimpleLabel} from '../../../../models/simple-label.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RuleService} from '../../../../services/rule.service';
import {SimpleCategory, SimpleCategoryValue} from '../../../../models/simple-category.model';
import {BothOrNoneCategoryValidator} from '../../../../validators/custom-validators';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {SortedLabels} from '../../../../models/sorted-labels.model';
import {SimpleDocumentClass} from '../../../../models/simple-document-class.model';
import {UserService} from '../../../../services/user.service';
import {AuthService} from '../../../../services/auth.service';
import {AuthProvider, convertToEmailProvider} from '../../../../constants/auth-provider-types';
import {Folder} from '../../../../models/folder.model';
import {LocalStorageUtils} from '../../../../services/local-storage-utils';
import {catchError, debounceTime, filter, switchMap} from 'rxjs/operators';
import {LibrariesResponse} from '../../../../models/libraries-response.model';

@Component({
  selector: 'app-rule-creator-editor',
  templateUrl: './rule-creator-editor.component.html',
  styleUrls: ['./rule-creator-editor.component.scss']
})
export class RuleCreatorEditorComponent implements OnInit, OnDestroy {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public rule?: Rule;
  public libraries?: SimpleLibrary[];
  public sortedLabels: SortedLabels[];

  public labels: SimpleLabel[];
  public categories?: SimpleCategory[];
  public documentClasses: SimpleDocumentClass[];
  public categoryValues: SimpleCategoryValue[];
  public ruleForm: FormGroup;
  private ruleId: string;
  public librarySelected = false;
  public documentClassSelected = false;
  public isCategories = undefined;
  public showDocumentClassField = true;
  public filteredOptions: SimpleCategoryValue[];
  public categoryValuePath = '';
  private isFolderCategoryFocus = false;
  private manualUpdate = false;
  public libraryReadOnly = false;
  public authProvider: AuthProvider;
  public isGoogleProvider: boolean;
  public authProviderEnum = AuthProvider;
  private selectedLibrary: SimpleLibrary;
  private selectedDocumentClass: SimpleDocumentClass;
  private subscriptions: Subscription[] = [];

  @ViewChild('autoTrigger', {read: MatAutocompleteTrigger}) autoTrigger: MatAutocompleteTrigger;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Rule,
              private labelService: LabelService,
              private libraryService: LibraryService,
              private dialogService: DialogService,
              private ruleService: RuleService,
              private userService: UserService,
              private dialogRef: MatDialogRef<RuleCreatorEditorComponent>,
              private authService: AuthService,
              private fb: FormBuilder) {
    this.rule = data;
    this.authProvider = this.authService.authProvider;
    this.isGoogleProvider = this.authService.isGoogleProvider();
  }

  ngOnInit(): void {
    this.initForm();
    this.initCategoryValueFilter();

    if (this.authService.isGoogleProvider()) {
      this.loadLabels();
    } else if (!!this.rule) {
      const selected = (this.rule.labels as Folder[]).map(folder => ({id: folder.id, name: folder.name}));
      this.ruleForm.get('labels').setValue(selected);
    }

    this.setLibrariesSubscription();

    if (!!this.rule) {
      this.ruleForm.get('name').disable();
      this.ruleForm.get('library').disable();
      this.ruleForm.get('documentClass').disable();
      this.loadDocumentClasses(this.rule.library, false);
    } else {
      this.loadPersonalSettings();
    }
  }

  initForm(): void {
    let name = '';
    let description = '';
    let library: SimpleLibrary = null;
    let documentClass: SimpleDocumentClass = null;
    let includeSubFolder = false;
    if (this.rule) {
      this.librarySelected = true;
      this.ruleId = this.rule.id;
      name = this.rule.name;
      description = this.rule.description;
      library = this.rule.library;
      documentClass = this.rule.documentType;
      includeSubFolder = this.rule.includeSubFolder;
      this.initDocumentClassInputVisibility(library);
    }
    this.ruleForm = this.fb.group({
        name: new FormControl<string>(name, {validators: Validators.required, nonNullable: true}),
        description: new FormControl<string>(description, {nonNullable: true}),
        library: new FormControl<SimpleLibrary>(library, {validators: Validators.required}),
        documentClass: new FormControl<SimpleDocumentClass>(documentClass, {validators: Validators.required}),
        labels: new FormControl<SimpleLabel[] | Folder[]>([], {validators: Validators.required, nonNullable: true}),
        category: new FormControl<string[]>([], {nonNullable: true}),
        categoryValue: new FormControl<SimpleCategoryValue>(null),
        includeSubFolder: new FormControl<boolean>(
          {value: includeSubFolder, disabled: !!this.rule}, {nonNullable: true}
        ),
      },
      {validators: BothOrNoneCategoryValidator()}
    );
  }
  private setLibrariesSubscription(): void {
   const librarySub = this.ruleForm.get('library').valueChanges.pipe(
     filter((query) => !this.libraryReadOnly && typeof query === 'string'),
      debounceTime(300),
      switchMap((query: string) => {
        this.ruleForm.get('documentClass').setValue(null);
        this.documentClasses = [];
        this.documentClassSelected = false;
        this.librarySelected = false;
        return this.libraryService.list(query);
      }),
      catchError(error => {
        this.dialogService.error(this.getErrorMessage(error));
        return error;
      })
    ).subscribe((resp: LibrariesResponse) => {
      this.libraries = resp.libraries;
    });
   this.subscriptions.push(librarySub);
  }

  loadDocumentClasses(library: SimpleLibrary, userChange: boolean): void {
    this.loadingSubject.next(true);
    this.selectedLibrary = library;
    this.initDocumentClassInputVisibility(library);
    this.librarySelected = true;
    this.documentClassSelected = false;
    this.categoryValues = null;
    this.filteredOptions = [];
    this.ruleForm.get('categoryValue').disable();
    this.ruleForm.get('documentClass').disable();
    if (!!this.rule) {
      this.ruleForm.get('documentClass').setValue(this.rule.documentType);
      this.fetchCategories(library, this.rule.documentType);
    } else {
      const docClassSubscription = this.libraryService.getDocumentClasses(library.id, true)
        .subscribe((resp) => {
          this.ruleForm.get('documentClass').enable();
          this.documentClasses = resp.documentTypes;
          if (this.documentClasses) {
            if (userChange) {
              const emailClass = this.documentClasses.filter(clazz => clazz.name.toLowerCase() === 'email')[0] || this.documentClasses[0];
              this.ruleForm.get('documentClass').setValue(emailClass);
            } else if (!!this.rule) {
              this.ruleForm.get('documentClass').setValue(this.rule.documentType);
            }
            if (!!this.ruleForm.get('documentClass').value) {
              this.fetchCategories(library, this.ruleForm.get('documentClass').value);
            } else {
              this.loadingSubject.next(false);
            }
          }
        }, (error) => {
          this.loadingSubject.next(false);
          this.ruleForm.get('documentClass').enable();
          if (this.isLibraryAccessDenied(error)) {
            this.resetLibraryAfterAccessDenied();
          } else {
            this.dialogService.error(this.getErrorMessage(error));
          }
        });

      this.subscriptions.push(docClassSubscription);
    }
  }

  checkLibraryState(event: any): void {
    const key = event.keyCode || event.charCode;

    this.libraryReadOnly = key !== 8 && key !== 46 && this.librarySelected;
  }

  isCategoryValueBackspace(event: any): void {
    const key = event.keyCode || event.charCode;
    if (key === 8 || key === 46) {
      this.ruleForm.get('categoryValue').setValue(null);
    }
  }

  private resetLibraryAfterAccessDenied(): void {
    LocalStorageUtils.removeLastLibrary();
    LocalStorageUtils.removeLastDocumentType();
    this.ruleForm.get('library').setValue('');
    this.ruleForm.get('documentClass').setValue(undefined);
    this.librarySelected = false;
    this.ruleForm.get('library').enable();
  }

  private isLibraryAccessDenied(error: any): boolean {
    return error?.error?.message?.indexOf('Access denied to library') >= 0;
  }

  private loadLabels(): void {
    const listSubscription = this.labelService.list().subscribe((resp) => {
      this.sortedLabels = resp;
      if (this.rule) {
        let selected: SimpleLabel[] = [];
        this.sortedLabels.forEach(type => {
          const selectedInType = type.list.filter(o1 => (this.rule.labels as SimpleLabel[]).some(o2 => o1.id === o2.id));
          selected = [...selected, ...selectedInType];
        });
        this.ruleForm.get('labels').setValue(selected);
      }

    }, (error) => {
      this.loadingSubject.next(false);
      this.dialogService.error(this.getErrorMessage(error));
    });

    this.subscriptions.push(listSubscription);
  }

  handleFolderSelection(folders: Folder[]): void {
    this.ruleForm.get('labels').setValue(folders);
    this.ruleForm.get('labels').markAsTouched();
  }

  removeFolder(folder: Folder): void {
    const selected = this.ruleForm.get('labels').value.filter(f => f.id !== folder.id);
    this.ruleForm.get('labels').setValue(selected);
    this.ruleForm.get('labels').markAsTouched();
  }

  fetchCategories(library: SimpleLibrary, documentClass: SimpleDocumentClass): void {
    this.selectedDocumentClass = documentClass;
    this.ruleForm.get('categoryValue').setValue(null);
    this.ruleForm.get('category').setValue(null);
    this.categoryValues = null;
    this.filteredOptions = [];
    this.ruleForm.get('categoryValue').disable();
    if (!library) {
      library = this.ruleForm.get('library').value;
    }
    this.documentClassSelected = true;
    this.isCategories = undefined;
    this.loadingSubject.next(true);
    const classCategoriesSubscription = this.libraryService.classCategories(library.id, documentClass.id)
      .subscribe((resp) => {
        this.categories = resp;
        this.isCategories = this.categories && this.categories.length > 0;
        const currentCategory = this.categories.filter(val => val.id === this.rule?.category?.id)[0];
        if (this.rule?.category?.id && currentCategory) {
          this.ruleForm.get('category').setValue(currentCategory);
          this.fetchCategoryValues(currentCategory);
        } else {
          this.loadingSubject.next(false);
        }

        // Note: The ID of the store category and the list one had changed
        if (!!this.rule?.category?.id && !currentCategory) {
          this.dialogService.warning('Categories field of your rule needs to be configured again.');
        }
      }, (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(this.getErrorMessage(error));
        this.ruleForm.get('documentClass').setValue(null);
        this.documentClassSelected = false;
      });

    this.subscriptions.push(classCategoriesSubscription);
  }

  fetchCategoryValues(category: SimpleCategory): void {
    this.ruleForm.get('categoryValue').setValue(null);
    this.categoryValues = null;
    this.filteredOptions = [];
    if (category) {
      this.loadingSubject.next(true);
      this.ruleForm.get('categoryValue').disable();
      const library: SimpleLibrary = this.ruleForm.get('library').value;

      // Get the list of values, category Folder need another API to get the values
      let categoryListResponse: Observable<SimpleCategoryValue[]>;
      if ('FOLDER_CATEGORY' !== category.categoryId) {
        this.isFolderCategoryFocus = false;
        categoryListResponse = this.libraryService.categoryValues(library.id, category.categoryId);
      } else {
        this.isFolderCategoryFocus = true;
        categoryListResponse = this.libraryService.categoryFolderValues(library.id);
      }

      const categoryListSubscription = categoryListResponse.subscribe((resp) => {
        this.ruleForm.get('categoryValue').enable();
        this.categoryValues = resp;
        this.filterObject(null);
        if (this.rule && this.rule.categoryValue) {
          const val = this.categoryValues.filter(catVal => catVal.id === this.rule.categoryValue.id)[0];
          this.manualUpdate = true;
          this.ruleForm.get('categoryValue').setValue(val);
        }
        this.loadingSubject.next(false);
      }, (error) => {
        this.loadingSubject.next(false);
        this.ruleForm.get('categoryValue').enable();
        this.dialogService.error(this.getErrorMessage(error));
      });

      this.subscriptions.push(categoryListSubscription);
    } else {
      this.loadingSubject.next(false);
    }
  }

  saveOrUpdate(): void {
    LocalStorageUtils.setLastLibrary(this.selectedLibrary);
    LocalStorageUtils.setLastDocumentType(this.selectedDocumentClass);

    const rawValueForm = this.ruleForm.getRawValue();
    const rule: Rule = {
      id: this.ruleId,
      description: rawValueForm.description,
      name: rawValueForm.name,
      labels: rawValueForm.labels.map(label => ({...label, type: label.type || 'user'})),
      library: rawValueForm.library,
      documentType: rawValueForm.documentClass,
      category: rawValueForm.category,
      categoryValue: typeof rawValueForm.categoryValue !== 'string' ? rawValueForm.categoryValue : {name: rawValueForm.categoryValue},
      user: this.userService.user.email,
      emailProvider: convertToEmailProvider(this.authProvider),
      includeSubFolder: rawValueForm.includeSubFolder
    };
    this.loadingSubject.next(true);
    const createOrUpgradeSubscription = this.ruleService.createOrUpdate(rule, !!this.ruleId, this.authProvider)
      .subscribe(() => {
        this.loadingSubject.next(false);
        this.dialogRef.close(true);
    }, (error) => {
      this.loadingSubject.next(false);
      this.dialogService.error(this.getErrorMessage(error));
    });
    this.subscriptions.push(createOrUpgradeSubscription);
  }

  public displayByName(value: SimpleLibrary | SimpleLabel): string {
    if (value) {
      return value.name;
    }
  }

  displayByNameCategoryValue = value => {
    if (value) {
      return this.categoryValuePath.concat(value.name);
    }
  }

  initCategoryValueFilter(): void {
   const categoryValueSubscription = this.ruleForm.get('categoryValue').valueChanges
     .subscribe((value) => {
        if (!this.manualUpdate) {
          typeof value === 'string' || !value ? this.filterString(value) : this.filterObject(value);
        } else {
          this.manualUpdate = false;
        }
      });

   this.subscriptions.push(categoryValueSubscription);
  }

  private filterString(selectedValue: string): void {
    if (selectedValue) {
      this.categoryValuePath = '';
      const valueToSearch = selectedValue.split(' > ')[0].toLowerCase();
      const preList = this.categoryValues ? this.categoryValues.filter(value => value.parentId === '0') : this.categoryValues;
      this.filteredOptions = preList.filter(value => value.name.toLowerCase().includes(valueToSearch));
      // Stop writing a search for Folder if there are no result for this search
      if (this.isFolderCategoryFocus && this.filteredOptions.length === 0) {
        this.ruleForm.get('categoryValue').setValue(null);
      }
      this.autoTrigger.openPanel();
    } else {
      if (this.categoryValues) {
        this.filteredOptions = this.categoryValues.filter(value => value.parentId === '0');
        this.filteredOptions.sort((c1, c2) => c1.name.localeCompare(c2.name));
      } else {
        this.filteredOptions = [];
      }
      this.categoryValuePath = '';
    }
  }

  private filterObject(selectedValue: SimpleCategoryValue): void {
    if (selectedValue) {
      this.filteredOptions = this.categoryValues.filter(value => value.parentId === selectedValue.id);
      this.categoryValuePath = this.categoryValuePath.concat(selectedValue.name).concat(' > ');
    } else {
      this.filteredOptions = this.categoryValues ? this.categoryValues.filter(value => value.parentId === '0') : this.categoryValues;
      this.categoryValuePath = '';
    }
    this.filteredOptions.sort((c1, c2) => c1.name.localeCompare(c2.name));
  }

  private loadPersonalSettings(): void {
    const lastLibraryUsed = LocalStorageUtils.getLastLibrary();
    const lastDocumentTypeUsed = LocalStorageUtils.getLastDocumentType();
    if (lastLibraryUsed && lastDocumentTypeUsed) {
      this.ruleForm.get('library').setValue(lastLibraryUsed);
      this.ruleForm.get('documentClass').setValue(lastDocumentTypeUsed);
      this.loadDocumentClasses(lastLibraryUsed, false);
    }
  }

  open(evt): void {
    evt.stopPropagation();
    this.autoTrigger.openPanel();
  }

  private getErrorMessage(error: any): string {
    return error.error?.message ?? (error.error?.error?.message || 'Unknown error');
  }

  /**
   * The document class input field should be visible only for non TF libraries.
   * @param library, the selected library.
   */
  private initDocumentClassInputVisibility(library: SimpleLibrary): void {
    this.showDocumentClassField = !isTFLibrary(library);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
